<template>
  <div class="web-billing">
    <div class="big-left">
      <div class="bl-top">
        <div class="bt-item">
          <span class="bt-number">{{serviceNo}}</span>
          <span class="bt-text">服务号</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==0}" @click="choicePage(0)">
          <span class="bt-price">¥ {{itemTotal}}</span>
          <span class="bt-name">项目</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==1}" @click="choicePage(1)">
          <span class="bt-price">¥ {{skuTotal}}</span>
          <span class="bt-name">卖品</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==2}" @click="choicePage(2)">
          <span class="bt-price">¥ {{specTotal}}</span>
          <span class="bt-name">成品</span>
        </div>
      </div>
      <div class="bl-bottom">
        <div class="bb-item" @click="toMemberList">
          <img src="../../assets/images/tx.jpg" class="bc-img">
          <span>选择会员</span>
        </div>
        <div class="bb-item" v-if="member" @click="toShopItems">
          <span>商城</span>
        </div>
        <div class="bb-item" v-if="member" @click="toMemberItems">
          <span>套餐</span>
        </div>
        <div class="bb-item" v-if="member" @click="toMemberCards">
          <span v-if="memberCard.cardName">{{memberCard.cardName}}</span>
          <span style="margin-top: 6px;color: red;font-size: 13px"
                v-if="memberCard.cardName">¥ {{memberCard.balance}}</span>
          <span v-else>会员卡</span>
        </div>
        <div class="bb-item" @click="toMbInfo(member)">
          <img src="../../assets/images/tx1.jpg" class="bc-img">
          <span v-if="member">{{member.memberName}}</span>
          <span v-if="!member">散客</span>
        </div>
      </div>
    </div>
    <div class="big-right">
      <div class="br-top">
        <TabItem
          v-if="tabIndex == 0"
          ref="tabItem"
          :itemData="itemData"
          :itemTotal="itemTotal.toString()"
          :mc="memberCard"
          @setItems="setItems"
        />
        <TabSku
          v-if="tabIndex == 1"
          ref="tabSku"
          :skuData="skuData"
          :skuTotal="skuTotal"
          :mc="memberCard"
          @setSkus="setSkus"
        />
        <TabSpec
          v-if="tabIndex == 2"
          ref="specSku"
          :specData="specData"
          :specTotal="specTotal"
          :mc="memberCard"
          :memberId="memberId"
          @setSpecs="setSpecs"
        />
      </div>
      <div class="br-bottom">
        <div class="br-btn">
          <div style="width: 50%;">
            <span class="br-sex" :class="{activeSex:orderSex==1}" @click="setOrderSex(1)">男</span>
            <span class="br-sex" :class="{activeSex:orderSex==2}" @click="setOrderSex(2)">女</span>
          </div>
          <div style="width: 50%;float: right;display: flex;flex-direction: row">
            <span class="zj">总计：</span>
            <span class="total">￥{{ total }}</span>
            <button class="payment" @click="saveBillingOrder(1)">结 算</button>
            <button class="br-save" @click="updateOrder(0)">保存并返回</button>
          </div>
        </div>
      </div>
    </div>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      :mbType="mbType"
      @setMember="setMember"
      @setMemberCard="setMemberCard"
      @closeML="closeML"
      @toAddMember="toAddMember"
    />

    <TabMemberCards
      v-if="mcVisible"
      :mcVisible="mcVisible"
      :memberId="memberId"
      :memberCardId="mcId"
      @setMc="setMc"
      @recharge="recharge"
      @closeMC="closeMC"
    />

    <TabMemberItems
      v-if="miVisible"
      :miVisible="miVisible"
      :memberId="memberId"
      @setMemberItem="setMemberItem"
      @closeMI="closeMI"
    />


    <TabShopItems
      v-if="shopVisible"
      :shopVisible="shopVisible"
      :memberId="memberId"
      @setShopItem="setShopItem"
      @closeShop="closeShop"
    />


    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total"
      :member="member"
      :oid="orderId"
      :memberCard="memberCard"
      :skuData="skuData"
      :data="data"
      @closePay="closePay"
      @cleanData="cleanData"
      :type="type"
    />
  </div>
</template>

<script>
  import TabMemberList from "../common/member/memberList";
  import TabSaveMember from "../common/member/saveMember";
  import TabMemberCards from "../common/member/memberCards";
  import TabMemberItems from "../common/member/memberItems";
  import TabShopItems from "../common/member/shopItems";
  import TabPayment from "../billing/payment/payment";
  import TabItem from "./menber/items";
  import TabSku from "./menber/skus";
  import TabSpec from "./menber/specs";
  import {getToken} from "../../utils/auth";

  export default {
    name: "billing",
    components: {
      TabMemberList,
      TabSaveMember,
      TabMemberCards,
      TabMemberItems,
      TabShopItems,
      TabPayment,
      TabItem,
      TabSku,
      TabSpec,
    },
    data() {
      return {
        mbType: 'item',
        type: 'REORDER',
        orderId: '',
        orderSex: 1,
        tabIndex: 0,
        serviceNo: '',

        itemData: [],
        skuData: [],
        specData: [],
        data: {},

        mcId: '',
        memberId: '',
        member: {},
        memberCards: [],
        memberCard: {},

        miNames: [],
        memberItem: {},

        itemTotal: 0,
        skuTotal: 0,
        specTotal: 0,
        total: 0,

        saveVisible: false,
        mbVisible: false,
        smVisible: false,
        mcVisible: false,
        miVisible: false,
        payVisible: false,
        shopVisible: false,
      };
    },

    async created() {
      this.orderId = this.$route.query.orderId
      await this.getOrder()
      await this.getMember()
    },

    methods: {
      //获取会员信息
      async getOrder() {
        let res = await this.$get("/sw/getSwOrder", {orderId: this.orderId});
        if (res.order.itemStr){
          this.itemData = JSON.parse(res.order.itemStr)
          if (this.itemData.length > 0) {
            this.itemData.forEach(item => {
              this.itemTotal = parseFloat(this.itemTotal) + parseFloat(item.price)

            })
            if (this.$refs.tabItem) {
              this.$refs.tabItem.setItemList(this.itemData, parseFloat(this.itemTotal))
            }
          }
        }
        if (res.order.skuStr){
          this.skuData = JSON.parse(res.order.skuStr)
          if (this.skuData.length > 0) {
            this.skuData.forEach(item => {
              this.skuTotal = parseFloat(this.skuTotal) + parseFloat(item.price)
            })
          }
        }
       if (res.order.specStr){
         this.specData = JSON.parse(res.order.specStr)
         if (!this.specData) {
           this.specData = []
         }
         if (this.specData.length > 0) {
           this.specData.forEach(item => {
             this.specTotal = parseFloat(this.specTotal) + parseFloat(item.price)
           })
         }
       }
        this.total = res.order.totalPrice
        this.memberId = res.order.memberId
        this.mcId = res.order.mcId
        this.serviceNo = res.order.serviceNo
        this.orderSex = res.order.orderSex
      },

      //获取会员信息
      async getMember() {
        let data = {
          memberId: this.memberId,
          mcId: this.mcId
        }
        let res = await this.$get("/sw/getSwMbmber", data);
        this.member = res.member
        if (res.member) {
          this.orderSex = res.member.sex
        }
        if (res.memberCard && res.memberCard.id) {
          this.memberCard = res.memberCard
          if (this.$refs.tabItem) {
            this.$refs.tabItem.setMemberCard(this.memberCard)
          }
          if (this.$refs.tabSku) {
            this.$refs.tabSku.setMemberCard(this.memberCard)
          }
        }
      },

      toMbInfo(item) {
        if (item.id) {
          this.$router.push(`/customers/memberInfo?memberId=` + item.id);
        }
      },


      //选择页面
      choicePage(index) {
        this.tabIndex = index
      },

      //选择性别
      setOrderSex(sex) {
        if (this.member) {
          return;
        }
        this.orderSex = sex
      },

      //设置项目列表
      setItems(items, total, mi) {
        this.itemData = items
        this.itemTotal = total
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
        if (mi && mi.memberItemId) {
          this.miNames.forEach(item => {
            item.memberItems.forEach(it => {
              if (it.id == mi.memberItemId) {
                it.uses = it.uses - 1
              }
            })
          })
        }
      },

      //设置卖品列表
      setSkus(skus, total) {
        this.skuData = skus
        this.skuTotal = parseFloat(total)
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
      },
      //设置成品列表
      setSpecs(specs, total) {
        this.specData = specs
        this.specTotal = parseFloat(total)
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
      },


      toMemberCards() {
        if (this.memberCard) {
          this.mcVisible = true
        }
      },
      toMemberItems() {
        this.miVisible = true
      },
      toShopItems() {
        this.shopVisible = true
      },

      toMemberList() {
        this.mbVisible = true
      },

      //选择会员信息
      setMember(item) {
        this.smVisible = false
        this.member = item;
        if (item) {
          this.activeSex = item.sex
        }
        this.memberId = item.id
        this.memberCard = {}
        this.mcId = ''
        this.closeML();
      },

      //选择会员卡
      setMemberCard(mb, mc) {
        this.smVisible = false
        if (mb.id) {
          this.member = mb;
          this.memberId = mb.id
          this.activeSex = mb.sex
        }
        if (mc.id) {
          this.memberCard = mc
          this.mcId = mc.id
        }
        if (this.$refs.tabItem) {
          this.$refs.tabItem.setItemsPrice(this.memberCard)
        }
        if (this.$refs.tabSku) {
          this.$refs.tabSku.setSkusPrice(this.memberCard)
        }
        this.closeML();
      },

      sanke() {
        this.smVisible = false
        this.member = null
        this.activeSex = 1
        this.memberId = ''
        this.memberCard = {}
        this.memberCards = []
        this.closeML();
        if (this.$refs.tabItem) {
          this.$refs.tabItem.setItemsPrice(this.memberCard)
        }
        if (this.$refs.tabSku) {
          this.$refs.tabSku.setSkusPrice(this.memberCard)
        }
      },

      setMemberItem(data) {
        let tcNum=data.tcNum
        let item=data.item
        let itemSign = 0
        if (item.miType == 0) {
          //套餐
          itemSign = 1
        }
        if (item.miType == 1) {
          //会员卡
          itemSign = 2
        }
        if (item.miType == 2) {
          //年卡
          itemSign = 3
        }
        if (item.miType == 3) {
          //单项套餐
          itemSign = 4
        }
        for (let i = 1; i <= tcNum; i++) {
          this.itemData.push({
            itemId: item.itemId,
            categoryId: item.categoryId,
            storeDeptId: item.storeDeptId,
            itemName: item.itemName,
            price: 0,
            actual: item.achievement,
            oglPrice: item.oglPrice,
            itemSign: itemSign,
            discount: 0,
            memberItemId: item.id,
            fach: item.fach,
            sach: item.sach,
            tach: item.tach,
            femp: {},
            semp: {},
            temp: {},
          })
        }
        this.miVisible = false;
        this.tabIndex = 0
      },

      setShopItem(item) {
        let itemSign = 0
        if (item.lyType == 4) {
          //秒杀套餐
          itemSign = 5
        }
        if (item.lyType == 10) {
          //请客卡
          itemSign = 10
        }
        this.itemData.push({
          itemId: item.itemId,
          categoryId: item.categoryId,
          itemName: item.itemName,
          price: 0,
          actual: item.mdAch,
          oglPrice: item.price,
          itemSign: itemSign,
          discount: 0,
          memberItemId: item.id,
          first: item.firstAch,
          second: item.secondAch,
          third: item.thirdAch,
          firstCmn: item.firstCmn,
          secondCmn: item.secondCmn,
          thirdCmn: item.thirdCmn,
          femp: {},
          semp: {},
          temp: {},
        })
        this.shopVisible = false;
        this.tabIndex = 0
      },

      recharge() {
        if (this.memberCard.id) {
          this.getMember(this.memberCard.id)
        }
        this.mcVisible = false
      },

      //计算总金额
      calculation(total) {
        this.total = total
      },

      async saveBillingOrder() {
        let mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        var data = {
          token: getToken(),
          orderId: this.orderId,
          itemStr: JSON.stringify(this.itemData),
          skuStr: JSON.stringify(this.skuData),
          specStr: JSON.stringify(this.specData),
          total: this.total,
          orderSex: this.orderSex,
          memberId: this.memberId,
          mcId: mcId,
          hh: null,
          payType: '',
        }
        this.data = data
        this.payVisible = true
      },

      async updateOrder() {
        let mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        var data = {
          orderId: this.orderId,
          itemStr: JSON.stringify(this.itemData),
          skuStr: JSON.stringify(this.skuData),
          specStr: JSON.stringify(this.specData),
          total: this.total,
          orderSex: this.orderSex,
          memberId: this.memberId,
          mcId: mcId,
        }
        let res = await this.$post("/sw/updateBillingOrder", data);
        if (res.code == 200) {
          this.$router.go(-1)
        } else {
          this.$message.error(res.message);
        }
      },

      closePay() {
        this.payVisible = false;
      },

      toAddMember() {
        this.smVisible = true;
      },

      closeSM() {
        this.smVisible = false;
      },
      closeMI() {
        this.miVisible = false;
      },
      closeShop() {
        this.shopVisible = false;
      },

      setMc(item) {
        this.memberCard = item
        this.mcId = item.id
        if (this.$refs.tabItem) {
          this.$refs.tabItem.setItemsPrice(this.memberCard)
        }
        if (this.$refs.tabSku) {
          this.$refs.tabSku.setSkusPrice(this.memberCard)
        }
        this.mcVisible = false;
      },

      closeMC() {
        this.mcVisible = false;
      },

      cleanData() {

      },

      toMembers() {
        this.mbVisible = true;
      },

      closeML() {
        this.mbVisible = false;
      },

    },

  };
</script>


<style lang="scss" scoped>
  .web-billing {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
  }

  .big-left {
    width: 78px;
    border-right: #DDDDDD solid 1px;
  }

  .bl-top {
    height: 50%;
  }

  .bt-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    justify-content: center;
    align-items: center;
    border-bottom: #DDDDDD solid 1px;
  }

  .bt-number {
    color: #ff4d51;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .bt-text {
    color: #A2A2A2;
    font-size: 15px;
  }

  .bt-name {

  }

  .bt-price {
    width: 60px;
    color: #FFFFFF;
    background-color: #DE4673;
    margin-top: -6px;
    margin-bottom: 10px;
    height: 18px;
    line-height: 18px;
    border-radius: 5px;
  }

  .bl-bottom {
    display: flex;
    flex-direction: column-reverse;
    height: 50%;
    width: 80px;
    font-size: 10px;
  }

  .bb-user {
    height: 15%;
  }

  .bb-card {
    height: 15%;
  }

  .bb-tc {
    height: 15%;
  }

  .bb-shop {
    height: 15%;
  }

  .bb-item {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-top: #DDDDDD solid 1px;
    cursor: pointer;
  }

  .bb-item span {

  }

  .bc-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 6px;
  }

  .big-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .br-top {
    height: 100%;
  }

  .br-bottom {
    width: 100%;
    height: 60px;
    border-top: #DDDDDD solid 1px;
  }

  .br-btn {
    width: 100%;
    /*height: 100%;*/
    float: right;
    display: flex;
    align-items: center;
  }

  .br-btn .zj {
    width: 45%;
    line-height: 60px;
    text-align: right;
  }

  .br-btn .total {
    width: 120px;
    line-height: 60px;
    color: red;
  }

  .payment {
    width: 150px;
    height: 60px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .br-save {
    width: 150px;
    height: 60px;
    border: 0;
    background-color: #595959;
    color: #fff;
    cursor: pointer;
    margin-left: 18px;
  }

  .br-sex {
    display: inline-block;
    background-color: #C2C6CD;
    margin-left: 12px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    border-radius: 45px;
    font-size: 16px;
    cursor: pointer;
  }

  .activeSex {
    background-color: #666666;
    color: #ffffff;
  }

</style>
